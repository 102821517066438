<template>
  <div class="login">
    <div class="logint">
      <div class="container">
        <img alt="" src="@/assets/img/login/logo.png"/>
      </div>
    </div>
    <el-row class="login-cont">
      <el-col :sm="12" :xs="11" class="contr">
        <h2>欢迎登录 <font>知者云（企业版）</font></h2>
        <form ref="accountRef" class="contr-account">
          <el-row>
            <el-col :sm="5" :xs="5">
              <img alt="" src="@/assets/img/login/icon-phone.png"/>
            </el-col>
            <el-col :sm="18" :xs="18">
              <input
                  v-model="param.phone"
                  placeholder="请输入您的账号"
                  type="text"
              />
            </el-col>
          </el-row>
          <div class="">
            <img alt="" src="@/assets/img/login/icon-key.png"/>
            <input
                v-model="param.password"
                placeholder="请输入您的密码"
                type="text"
            />
          </div>
          <button class="cont-sub">登录</button>
        </form>
        <form ref="param" class="contr-phone">
          <el-row>
            <el-col :sm="4" :xs="4">
              <img alt="" src="@/assets/img/login/icon-phone.png"/>
            </el-col>
            <el-col :sm="20" :xs="20">
              <input
                  v-model="param.phone"
                  autocomplete="on" name="phone"
                  maxlength="11"
                  placeholder="请输入手机号"
                  type="text"
              />
            </el-col>
          </el-row>
          <el-row class="phone-code" >
            <el-col :sm="4" :xs="4">
              <img alt="" src="@/assets/img/login/icon-key.png"/>
            </el-col>
            <el-col :sm="11" :xs="11">
              <input
                  v-model="param.code"
                  @keyup.enter="doLogin"
                  ref="codeRef"
                  placeholder="输入验证码"
                  type="text" maxlength="6"
              />
            </el-col>
            <el-col :sm="9" :xs="9">
              <el-button v-if="sent" class="btn" disabled type="text">重新获取 {{ seconds }}</el-button>
              <el-button v-else class="btn" type="text" @click="getCode">获取验证码</el-button>
            </el-col>
          </el-row>

          <el-button v-if="pending" :loading="true" class="cont-sub" type="primary">正在登录</el-button>
          <el-button v-else class="cont-sub" type="primary" @click="doLogin">登录</el-button>

          <div class="agree" ><span @click="checkedChange" :class="{on:checked,animation:shake}">
            <i v-if="checked" class="el-icon-check"></i>
            </span> 已阅读同意 <router-link to="/agree" class="col-blue" target="_blank" >《用户服务协议》</router-link>，
            <router-link to="/conceal"  target="_blank" class="" >《隐私政策》</router-link>
          </div>
        </form>
      </el-col>
    </el-row>
    <div class="loginb">
      © {{ year }} 南京知者云科技有限公司 <a href="http://beian.miit.gov.cn/" target="_blank">苏ICP备2021053453号</a>
    </div>
  </div>
</template>

<script>
import storage from '@/utils/storage'

export default {
  name: 'Login',

  data() {
    return {
      shake:false,
      checked:false,
      agreement:false,
      year: new Date().getFullYear(),
      param: {
        phone: '',//13585831232
        code: null,
        verify_type: 1
      },
      img: 1,
      dargPass: false,
      sent: false,
      pending: false,
      seconds: 0,
    }
  },
  computed: {
    gettoken: function () {
      return this.$store.state.token;
    },

  },
created() {
  this.checked = storage.getChecked() ? true : false
},
  methods: {
    getCode() {
      if (/^1[2-9]\d{9}$/.test(this.param.phone)) {
        this.$refs.codeRef.focus()
        this.dargPass = true
        this.sent = true
        this.seconds = 119
        this.countdown()
        this.$http.post('/api/loginCode', {phone: this.param.phone}).then((res) => {
          if (res.err_code !== 0) {
            this.sent = false
            this.seconds = 0
            this.$message.error(res.err_msg)
          }
        })
      } else {
        this.$message.warning('请输入正确的手机号码！')
      }
    },

    // 倒计时
    countdown() {
      if (this.sent && this.seconds > 0) {
        setTimeout(() => {
          this.seconds--;
          this.countdown();
        }, 1000);
      } else {
        this.sent = false;
      }
    },
    // 刷新
    async refresh() {
      let num = Math.floor(Math.random() * 6)
      if (num === this.img) {
        await this.refresh()
      }
      this.$set(this, 'img', num)
    },
    async doLogin() {
      if (!this.dargPass) {
        this.$message.warning('请点击获取验证码')
      } else if (!this.param.code) {
        this.$message.warning('请输入验证码')
      }else if(!this.checked){
        this.$message.warning('请勾选同意协议')
        this.shake = true
        setTimeout(() => (this.shake = false), 100)
      } else {
        this.pending = true
        await this.$http.post('/api/login', this.param).then(async res => {
          if (res && res.err_code === 0) {
            storage.setToken(res.data)
            this.$store.commit('settoken',{
              user:storage.getToken(),
            });
            await this.$http.post('/api/user/detail').then(res => {
              if (res && res.err_code === 0) {
                storage.setUser(res.data)
                // storage.getSelects('app_names', 'users')
                this.$store.commit('setuser',{
                  user:storage.getUser(),
                });
              }
            })
            if(!storage.getVersion()){
              storage.setVersion('1.0.0')
            }
            await this.$router.push('/personal/')
          } else {
            this.$message.error(res.err_msg)
          }
        }).catch((err) => {
          this.pending = false
        })
        this.pending = false
      }
    },
    checkedChange(){
      this.checked = !this.checked
      storage.setChecked(this.checked)
    }
  },
}
</script>

<style lang="less" scoped>
@import "../../style/login.less";
</style>
