<template>
  <div class="agree">
    <div class="agree_t w100 pos-fix pos-l0 pos-t0 ba-col-fff">
      <img src="@/assets/img/personal/agree.png" alt="">
    </div>
    <div class="agree_c pos-fix w100 pos-l0 ">
      <router-view ></router-view>
    </div>

    <div class="agree_b text-cen w100  col-000 pos-fix pos-l0 pos-b0">
      Copyright © {{ year }} 南京知者云科技有限公司 All Rights Reserved
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default{
  data(){
    return{
      year: moment().format('Y'),
    }
  }
}
</script>
<style lang="less" scoped>
.agree{
  .agree_t{
  line-height: 50px;
    padding: 0 30px;
  }
  .agree_c{
    height: calc(100vh - 100px);
    top: 60px;
  }
  .agree_b{
    background: #F4F4F4;
  }
}
@media screen and (max-width: 400px){
  .agree_b{
    line-height:20px;
  }
}
@media screen and (min-width: 400px){
.agree_b{
  line-height: 40px;
}
}

</style>